import "core-js/stable";
// import "regenerator-runtime/runtime";

// CONFIG
// import CONFIG from './config';

// Vue
import Vue from 'vue';

// UIkit
import UIkit from 'uikit';
window.UIkit = UIkit;
